import ar_SA from "@/I18n/ar_SA.json";
import de from "@/I18n/de.json";
import en_US from "@/I18n/en_US.json";
import es from "@/I18n/es.json";
import fr from "@/I18n/fr.json";
import it from "@/I18n/it.json";
import pl from "@/I18n/pl.json";
import pt from "@/I18n/pt.json";
import ru_RU from "@/I18n/ru_RU.json";
import zh_CN from "@/I18n/zh_CN.json";
import zh_HK from "@/I18n/zh_HK.json";
import { APP_CONFIG } from "../../../../utils/appConstant";
// export const langAlexaLogin = [{}, {}, {}];

// export const langPolicy = [];

// export const langCreateAccount = [{}, {}, {}, {}, {}];

export const langVerificationCode = [{}, {}, {}, {}, {}];

// export const langNoCode = [
//   {
//     title:
//       "If you do not receive the verification code of your mobile phone, it is recommended that you do the following",
//     one: "Please check whether the country / region of the app registration page is selected correctly",
//     tow: "Please check if your mobile phone is down or there is no network",
//     three: "Please check whether the mobile phone you input is correct",
//     four: "Please check whether your SMS verification code is blocked or isolated by the system",
//     five: "The upper limit for each mobile phone number to obtain the verification code is 15 times a day. If it exceeds the limit, you can not obtain the verification code again. You can try again the next day",
//   },
//   {
//     title: "请输入验证码",
//     one: "验证码已发送到您的邮箱",
//     tow: "重新发送",
//     three: "没有收到验证码?",
//     four: "没有收到验证码?",
//     five: "没有收到验证码?",
//   },
// ];

export const langResetPws = [{}, {}, {}, {}, {}];

export const langSetPws = [{}, {}, {}, {}, {}];

export const langBind = [{}, {}, {}, {}, {}];

export const noVerification = [{}, {}, {}, {}, {}];
export const selPort = [{}, {}, {}, {}, {}];

export const language = [
  en_US,
  zh_CN,
  zh_HK,
  ru_RU,
  fr,
  de,
  es,
  it,
  pl,
  pt,
  ar_SA,
];

export const LANGS_ARR = {
  1020: [
    "en_US",
    "zh_CN",
    "zh_HK",
    "ru_RU",
    "fr",
    "de",
    "es",
    "it",
    "pl",
    "pt",
    "ar_SA",
  ],
  1104: ["en_US", "fr"],
};

export const replaceTitleByAppId = (langArr, code) => {
  if (code === "1104") {
    //pa
    const temp = langArr;
    const result = [];
    temp.map((item) => {
      //循环数组
      const customeItem = {};
      for (var i in item) {
        customeItem[i] = item[i]
          .replace("Dollin Smart", APP_CONFIG[code].appName)
          .replace("1020", code);
      }
      result.push(customeItem);
    });
    return result;
  } else if (code !== "1020") {
    //pa
    const temp = langArr;
    const result = [];
    temp.map((item) => {
      //循环数组
      const customeItem = {};
      for (var i in item) {
        customeItem[i] = item[i].replace(
          "Dollin Smart",
          APP_CONFIG[code].appName
        );
        if (i === "licenseLink") {
          customeItem[i] = APP_CONFIG[code].licenseLink;
        }
        if (i === "policyLink") {
          customeItem[i] = APP_CONFIG[code].policyLink;
        }
      }
      result.push(customeItem);
    });
    return result;
  }
  return langArr;
};
