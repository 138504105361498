
import { Options, Vue } from 'vue-class-component';
import HelloWorld from '@/components/HelloWorld.vue'; 
import Menu from '@/components/Menu.vue'; 
import Search from '@/components/Search.vue'; 

@Options({
  components: {
    HelloWorld,
    Menu,
    Search
  },
  data() {
    return { 
      sliders: [
        require("@/assets/bk1.png"),
        require("@/assets/bk2.png")
      ],
      collections: [
        '../assets/collection1.png',
        '../assets/collection1.png',
      ],
      list: ["Today’s","Healthy", "Bakery", "Specialty", "Nutrition"],
      popular: [
        {
          "image": require("@/assets/populer1.png"),
          "name": "糖醋里脊",
          "zan": 10,
          "view": 297
        },
        {
          "image": require("@/assets/populer2.png"),
          "name": "糖醋里脊",
          "zan": 10,
          "view": 297
        },
        {
          "image": require("@/assets/populer1.png"),
          "name": "糖醋里脊",
          "zan": 10,
          "view": 297
        },
        {
          "image": require("@/assets/populer2.png"),
          "name": "糖醋里脊",
          "zan": 10,
          "view": 297
        }
      ]
    }
  }
})
export default class Home extends Vue {
  created () {
    console.log("hello world") // -> Hello
  }
  beforeMoving(dire) {
    console.log("before", dire);
  }
  afterMoving(obj) {
    console.log("after", obj);
  }
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  goCollection() {
    console.log('ok');
    this.$router.push({"path": "/collection"})
  }
}
