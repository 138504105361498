<template>
  <!-- <div id="nav">
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </div> -->
  <router-view />
</template>
<script>
import { mapMutations, mapState } from "vuex";
export default {
  computed: {
    ...mapState("alexaLogin", ["tabIndex", "portList"]),
    scope() {
      let _scope = "";
      switch (this.$commonFunc.getUrlChannel()) {
        case "alexa":
          _scope = "ALEXA";
          break;
        case "google":
          _scope = "GOOGLE";
          break;
        case "yandex":
          ``;
          _scope = "YANDEX";
          break;
        case "ifttt":
          _scope = "IFTTT";
          break;
        default:
          break;
      }
      return _scope;
    },
  },
  mounted() {
    //初始化信息
    if (this.scope === "YANDEX") {
      //yandex默认俄语
      this.setTabIndex(3);
    } else {
      this.setTabIndex(this.tabIndex || 0);
    }
    const _portList = localStorage.getItem("portList");
    if (this.portList.length === 0 && _portList !== "undefined" && _portList) {
      //默认没值时取缓存
      this.setPortList(JSON.parse(_portList));
    }
    // console.log('commonFunc.getUrlAppId',this.$commonFunc.getUrlAppId())
    this.setLanguagePackage(this.$commonFunc.getUrlAppId());
  },
  methods: {
    ...mapMutations("alexaLogin", [
      "setTabIndex",
      "setPortList",
      "setLanguagePackage",
    ]),
  },
};
</script>

<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  width: 100%;
  height: 100%;
  flex: 1;
  margin: 0 auto;
}
body {
  padding: 0;
  margin: 0;
}
#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
