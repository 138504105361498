import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Home from "../views/Home.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/collection",
    name: "Collection",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/collection.vue"),
  },
  {
    path: "/detail",
    name: "detail",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/detail.vue"),
  },
  {
    path: "/alexa",
    redirect: "/alexa/1020",
    component: () => import("../views/login/alexa/index.vue"),
    props: true,
    children: [
      {
        path: "login",
        component: () => import("../views/login/alexa/alexa-login.vue"),
      },
      {
        path: "sel-port",
        component: () => import("../views/login/alexa/sel-port.vue"),
      },
      {
        path: "confirm-bind",
        component: () => import("../views/login/alexa/confirm-bind.vue"),
      },
      {
        path: "create-account",
        component: () => import("../views/login/alexa/create-account.vue"),
      },
      {
        path: "no-code-received",
        component: () => import("../views/login/alexa/no-code-received.vue"),
      },
      {
        path: "reset-password",
        component: () => import("../views/login/alexa/reset-password.vue"),
      },
      {
        path: "set-password",
        component: () => import("../views/login/alexa/set-password.vue"),
      },
      {
        path: "verification-code",
        component: () => import("../views/login/alexa/verification-code.vue"),
      },
      {
        path: "lang-switch",
        component: () => import("../views/login/alexa/lang-switch.vue"),
      },
    ],
  },
  {
    path: "/alexa/:appId",
    component: () => import("../views/login/alexa/index.vue"),
    props: true,
    children: [
      {
        path: "login",
        name: "alexaLogin",
        component: () => import("../views/login/alexa/alexa-login.vue"),
      },
      {
        path: "sel-port",
        component: () => import("../views/login/alexa/sel-port.vue"),
      },
      {
        path: "confirm-bind",
        component: () => import("../views/login/alexa/confirm-bind.vue"),
      },
      {
        path: "create-account",
        component: () => import("../views/login/alexa/create-account.vue"),
      },
      {
        path: "no-code-received",
        component: () => import("../views/login/alexa/no-code-received.vue"),
      },
      {
        path: "reset-password",
        component: () => import("../views/login/alexa/reset-password.vue"),
      },
      {
        path: "set-password",
        component: () => import("../views/login/alexa/set-password.vue"),
      },
      {
        path: "verification-code",
        component: () => import("../views/login/alexa/verification-code.vue"),
      },
      {
        path: "lang-switch",
        component: () => import("../views/login/alexa/lang-switch.vue"),
      },
    ],
  },
  {
    path: "/google",
    redirect: "/google/1020",
    component: () => import("../views/login/alexa/index.vue"),
    props: true,
    children: [
      {
        path: "login",
        component: () => import("../views/login/alexa/alexa-login.vue"),
      },
      {
        path: "sel-port",
        component: () => import("../views/login/alexa/sel-port.vue"),
      },
      {
        path: "confirm-bind",
        component: () => import("../views/login/alexa/confirm-bind.vue"),
      },
      {
        path: "create-account",
        component: () => import("../views/login/alexa/create-account.vue"),
      },
      {
        path: "no-code-received",
        component: () => import("../views/login/alexa/no-code-received.vue"),
      },
      {
        path: "reset-password",
        component: () => import("../views/login/alexa/reset-password.vue"),
      },
      {
        path: "set-password",
        component: () => import("../views/login/alexa/set-password.vue"),
      },
      {
        path: "verification-code",
        component: () => import("../views/login/alexa/verification-code.vue"),
      },
      {
        path: "get-code",
        component: () => import("../views/login/alexa/get-code.vue"),
      },
      {
        path: "cancel-success",
        component: () => import("../views/login/alexa/cancel-success.vue"),
      },
      {
        path: "lang-switch",
        component: () => import("../views/login/alexa/lang-switch.vue"),
      },
      {
        path: "learn",
        component: () => import("../views/login/alexa/lang-switch.vue"),
      },
    ],
  },
  {
    path: "/google/:appId",
    component: () => import("../views/login/alexa/index.vue"),
    props: true,
    children: [
      {
        path: "login",
        component: () => import("../views/login/alexa/alexa-login.vue"),
      },
      {
        path: "sel-port",
        component: () => import("../views/login/alexa/sel-port.vue"),
      },
      {
        path: "confirm-bind",
        component: () => import("../views/login/alexa/confirm-bind.vue"),
      },
      {
        path: "create-account",
        component: () => import("../views/login/alexa/create-account.vue"),
      },
      {
        path: "no-code-received",
        component: () => import("../views/login/alexa/no-code-received.vue"),
      },
      {
        path: "reset-password",
        component: () => import("../views/login/alexa/reset-password.vue"),
      },
      {
        path: "set-password",
        component: () => import("../views/login/alexa/set-password.vue"),
      },
      {
        path: "verification-code",
        component: () => import("../views/login/alexa/verification-code.vue"),
      },
      {
        path: "get-code",
        component: () => import("../views/login/alexa/get-code.vue"),
      },
      {
        path: "cancel-success",
        component: () => import("../views/login/alexa/cancel-success.vue"),
      },
      {
        path: "lang-switch",
        component: () => import("../views/login/alexa/lang-switch.vue"),
      },
      {
        path: "learn",
        component: () => import("../views/login/alexa/lang-switch.vue"),
      },
      {
        path: "logOffAgreement",
        component: () => import("../views/login/alexa/log-off-agreement.vue"),
      },
    ],
  },
  {
    path: "/yandex",
    redirect: "/yandex/1020",
    component: () => import("../views/login/alexa/index.vue"),
    props: true,
    children: [
      {
        path: "login",
        component: () => import("../views/login/alexa/alexa-login.vue"),
      },
      {
        path: "sel-port",
        component: () => import("../views/login/alexa/sel-port.vue"),
      },
      {
        path: "confirm-bind",
        component: () => import("../views/login/alexa/confirm-bind.vue"),
      },
      {
        path: "create-account",
        component: () => import("../views/login/alexa/create-account.vue"),
      },
      {
        path: "no-code-received",
        component: () => import("../views/login/alexa/no-code-received.vue"),
      },
      {
        path: "reset-password",
        component: () => import("../views/login/alexa/reset-password.vue"),
      },
      {
        path: "set-password",
        component: () => import("../views/login/alexa/set-password.vue"),
      },
      {
        path: "verification-code",
        component: () => import("../views/login/alexa/verification-code.vue"),
      },
      {
        path: "lang-switch",
        component: () => import("../views/login/alexa/lang-switch.vue"),
      },
      {
        path: "learn",
        component: () => import("../views/login/alexa/lang-switch.vue"),
      },
    ],
  },
  {
    path: "/yandex/:appId",
    component: () => import("../views/login/alexa/index.vue"),
    props: true,
    children: [
      {
        path: "login",
        component: () => import("../views/login/alexa/alexa-login.vue"),
      },
      {
        path: "sel-port",
        component: () => import("../views/login/alexa/sel-port.vue"),
      },
      {
        path: "confirm-bind",
        component: () => import("../views/login/alexa/confirm-bind.vue"),
      },
      {
        path: "create-account",
        component: () => import("../views/login/alexa/create-account.vue"),
      },
      {
        path: "no-code-received",
        component: () => import("../views/login/alexa/no-code-received.vue"),
      },
      {
        path: "reset-password",
        component: () => import("../views/login/alexa/reset-password.vue"),
      },
      {
        path: "set-password",
        component: () => import("../views/login/alexa/set-password.vue"),
      },
      {
        path: "verification-code",
        component: () => import("../views/login/alexa/verification-code.vue"),
      },
      {
        path: "lang-switch",
        component: () => import("../views/login/alexa/lang-switch.vue"),
      },
      {
        path: "learn",
        component: () => import("../views/login/alexa/lang-switch.vue"),
      },
    ],
  },
  {
    path: "/ifttt",
    redirect: "/ifttt/1020",
    component: () => import("../views/login/alexa/index.vue"),
    props: true,
    children: [
      {
        path: "login",
        component: () => import("../views/login/alexa/alexa-login.vue"),
      },
      {
        path: "sel-port",
        component: () => import("../views/login/alexa/sel-port.vue"),
      },
      // {
      //   path: "confirm-bind",
      //   component: () => import("../views/login/alexa/confirm-bind.vue"),
      // },
      {
        path: "create-account",
        component: () => import("../views/login/alexa/create-account.vue"),
      },
      {
        path: "no-code-received",
        component: () => import("../views/login/alexa/no-code-received.vue"),
      },
      {
        path: "reset-password",
        component: () => import("../views/login/alexa/reset-password.vue"),
      },
      {
        path: "set-password",
        component: () => import("../views/login/alexa/set-password.vue"),
      },
      {
        path: "verification-code",
        component: () => import("../views/login/alexa/verification-code.vue"),
      },
      {
        path: "lang-switch",
        component: () => import("../views/login/alexa/lang-switch.vue"),
      },
      {
        path: "learn",
        component: () => import("../views/login/alexa/lang-switch.vue"),
      },
    ],
  },
  {
    path: "/ifttt/:appId",
    component: () => import("../views/login/alexa/index.vue"),
    props: true,
    children: [
      {
        path: "login",
        component: () => import("../views/login/alexa/alexa-login.vue"),
      },
      {
        path: "sel-port",
        component: () => import("../views/login/alexa/sel-port.vue"),
      },
      // {
      //   path: "confirm-bind",
      //   component: () => import("../views/login/alexa/confirm-bind.vue"),
      // },
      {
        path: "create-account",
        component: () => import("../views/login/alexa/create-account.vue"),
      },
      {
        path: "no-code-received",
        component: () => import("../views/login/alexa/no-code-received.vue"),
      },
      {
        path: "reset-password",
        component: () => import("../views/login/alexa/reset-password.vue"),
      },
      {
        path: "set-password",
        component: () => import("../views/login/alexa/set-password.vue"),
      },
      {
        path: "verification-code",
        component: () => import("../views/login/alexa/verification-code.vue"),
      },
      {
        path: "lang-switch",
        component: () => import("../views/login/alexa/lang-switch.vue"),
      },
      {
        path: "learn",
        component: () => import("../views/login/alexa/lang-switch.vue"),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
