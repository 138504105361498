import {
  language,
  replaceTitleByAppId,
} from "../../views/login/alexa/language";

const state = () => ({
  langList: [
    // 语言列表
    {
      title: "English",
      desc: "English",
    },
    {
      title: "Simplified Chinese",
      desc: "简体中文",
    },
    {
      title: "Traditional Chinese",
      desc: "繁體中文",
    },
    {
      title: "Russian",
      desc: "русский",
    },
    {
      title: "French",
      desc: "Français",
    },
    // de, es,it, pl, pt, ar_SA
    {
      title: "German",
      desc: "Deutsch",
    },
    {
      title: "Spanish",
      desc: "Español",
    },
    {
      title: "Italian",
      desc: "Italiano",
    },
    {
      title: "Polish",
      desc: "Polski",
    },
    {
      title: "Portuguese",
      desc: "Português",
    },
    {
      title: "Arabic",
      desc: "اللغة العربية",
    },
  ],
  languagePackage: language,
  portList: [],
  tabIndex: localStorage.getItem("tabIndex") || 0, // 默认选中
  portIndex: localStorage.getItem("portIndex") || 0,
});

// mutations
const mutations = {
  setTabIndex(state, index) {
    localStorage.setItem("tabIndex", index);
    state.tabIndex = index;
  },
  setPortList(state, val) {
    localStorage.setItem("portList", JSON.stringify(val));
    state.portList = val;
  },
  setPortIndex(state, index) {
    localStorage.setItem("portIndex", index);
    state.portIndex = index;
  },
  setLangList(state, appId) {
    switch (appId) {
      case "1104":
        state.langList = [
          {
            title: "English",
            desc: "English",
          },
          {
            title: "French",
            desc: "Français",
          },
        ];
        // console.log(appId, "appId----", state);
        return;
      default:
        break;
    }
  },
  setLanguagePackage(state, appId) {
    switch (appId) {
      case "1104":
        // const common = state.languagePackage;
        //英语和法语
        state.languagePackage = replaceTitleByAppId(
          [language[0], language[4]],
          appId
        );
        // console.log(appId, "appId----", state.languagePackage);
        return;
      default:
        break;
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
};
