import { createApp } from 'vue'
import App from './App.vue'
import { directive } from "./plugins/directives";
import "./assets/style/common.less";
import ElementPlus from 'element-plus'
import 'element-plus/theme-chalk/index.css'
import * as ElIcons from '@element-plus/icons'
import Toast from './plugins/toast'
import router from './router'
import store from './store'
import Carousel from "v3-carousel"// 引入
import CommonFunc from "@/utils/commonFunc";
const app = createApp(App) 
directive(app)
app.config.globalProperties.$commonFunc = CommonFunc
// 统一导入el-icon图标
for(const icon in ElIcons){
    app.component(icon,ElIcons[icon])
}
app.use(Toast).use(store).use(ElementPlus).use(Carousel).use(router).mount('#app')
