
import { Options, Vue } from 'vue-class-component';

@Options({
  props: {

  },
  methods: {
    clickMenu(event:unknown) {
      console.log(event);
      this.tab = event
    }
  }
  
})
export default class Search extends Vue {
  msg!: string
}
