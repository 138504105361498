export const directive = (App) => {
  App.directive("dbClick", {
    inserted(el: any): void {
      el.addEventListener("click", () => {
        if (!el.disabled) {
          el.disabled = true;
          el.style.cursor = "not-allowed";
          setTimeout(() => {
            el.style.cursor = "pointer";
            el.disabled = false;
          }, 1500);
        }
      });
    },
  });
  App.directive("no-space", {
    update: function (el) {
      el.addEventListener("keyup", function () {
        console.log(el, "!!!!!!!!!!!!!!!");
        const regex = /\s/g;
        const newValue = el.value.replace(regex, "");
        el.value = newValue;
        el.dispatchEvent(new Event("input"));
      });
    },
  });
};
