import toastTemplate from "./template/toast-template.vue";
import { createApp } from 'vue'
const toast = {
  install(Vue){
  // 扩展 vue 插件
  const ToastCon = createApp(toastTemplate);
    console.log("ToastCon",ToastCon)
  // 挂载 dom
  const vnode = document.createElement("div")
  const vm:any = ToastCon.mount(vnode);
    console.log('vm',vm)
  // 添加到 body 后面
  document.body.appendChild(vnode);

  // console.log("proxy",vm.visible)
  // 给 vue 原型添加 toast 方法
  Vue.config.globalProperties.$toast = (msg, duration = 1800) => {
    // 我们调用的时候 赋值 message
    // 将 visible 设置为 true
    // 默认 1.8s 之后 设置 为 false 关闭 toast
    vm.message = msg;
    vm.visible = true;
    setTimeout(() => {
      vm.visible = false;
    }, duration);
  };
}
};
export default toast;
