import { createStore } from 'vuex'
import alexaLogin from "./modules/alexa-login";

export default createStore({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
     alexaLogin,
  }
})
