<template>
  <transition name="alert-fade">
    <div id="toast" v-show="visible" class="dialog-tips dialog-center">
      {{ message }}
    </div>
  </transition>
</template>
<script>
export default {
  data() {
    return {
      visible: false,
      message: "",
    };
  },
};
</script>
<style lang="less" scoped>
.alert-fade-enter-active,
.alert-fade-leave-active {
  transition: opacity 0.3s;
}

.alert-fade-enter,
.alert-fade-leave-to {
  opacity: 0;
}

.dialog-tips {
  position: fixed;
  z-index: 100;
  height: auto; /* 改为自动高度 */
  width: 100%; /* 改为100%宽度 */
  max-width: 300px;
  min-width: 100px; /* no */
  padding: 10px 15px; /* no */
  border-radius: 12px; /* no */
  white-space: normal; /* 改为normal换行 */
  word-break: break-all; /* 添加word-break属性 */
  background: rgba(17, 17, 17, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 14px; /* no */
}

.dialog-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
